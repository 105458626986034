body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background-image: url("./assets/images/texture.svg");
  background-color: #272d34;
  color: #272d34;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Staatliches", cursive;
  }
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 16px;
  &.--wide {
    max-width: 1440px;
  }
}
.d-flex {
  display: flex;
}

header {
  padding: 12px;
  background: blue;
  color: white;
}

.tournament-listing-container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  padding: 100px 0;
}
a {
  text-decoration: none;
  .tournament {
    padding: 24px;
    position: relative;
    display: flex;
    border-radius: 6px;
    background: white;
    color: #272d34;
    cursor: pointer;
    transition: 0.225s ease-in all;
    &:hover {
      background: lighten(#0000aa, 45%);
    }
    .date {
      line-height: 1.25em;
      font-family: "Staatliches", cursive;
      font-size: 40px;
      padding: 0 24px 0 48px;
      text-align: center;
      position: relative;
      transition: 0.225s ease-in all;
      span {
        font-size: 80px;
        display: block;
        &.year {
          position: absolute;
          left: -25px;
          top: 34px;
          font-size: 50px;
          letter-spacing: 5px;
          transform: rotate(90deg);
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      h2 {
        max-width: 480px;
        margin: 0;
        font-size: 28px;
        font-family: "Roboto", sans-serif;
        font-weight: 900;
        line-height: 1.1em;
      }
    }
  }
}

@media screen and (max-width: 544px) {
  .tournament-listing-container a .tournament .date {
    line-height: 1.25em;
    font-size: 20px;
    padding: 0 12px 0 24px;
    span {
      font-size: 40px;
      display: block;
      &.year {
        left: -13px;
        top: 16px;
        font-size: 24px;
        letter-spacing: 2px;
      }
    }
  }
}

// ALGOLIA REVAMP
.ais-Hits,
.ais-SearchBox {
  margin: 20px 0;
}
.ais-SearchBox-input {
  min-height: 72px;
}

.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Results-item {
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 0;
}
